






























































import { ApiGetCustomerDto } from "@/api/generated/Api";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { hasMemberOrgAccess } from "@/shared/helpers/accessLevelApiAdapter";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { isValidStringProperty } from "@/shared/helpers/typeHelpers";
import { defineComponent, PropType } from "@vue/composition-api";

export const genderMap = {
  m: "Mann",
  k: "Kvinne",
  a: "Anonym",
} as const;

export default defineComponent({
  name: "ParticipantGeneralInfo",
  components: { BaseSheetField, BaseSheetHeader },
  props: {
    contact: {
      type: Object as PropType<ApiGetCustomerDto>,
      required: true,
    },
  },
  setup() {
    const getGender = (genderString: string) => {
      const gender = genderString?.toLowerCase();
      if (isValidStringProperty(genderMap, gender)) {
        return genderMap[gender];
      }
      return "Ukjent";
    };

    return {
      formatDate,
      getGender,
      hasMemberOrgAccess,
    };
  },
});
